<template>
  <div
    class="userservic"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 搜索 -->
    <div class="search">
      <div class="top-select">
        <el-form
          label-width="80px"
          style="display: flex; flex-wrap: wrap; align-items: center"
        >
          <el-form-item label="上架时间" style="width: 48%">
            <el-date-picker
              class="in1"
              v-model="value1"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 170px"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              @input="$forceUpdate()"
            >
            </el-date-picker
            >-
            <el-date-picker
              class="in2"
              v-model="value2"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 170px"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              @input="$forceUpdate()"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="广告类型">
            <el-col>
              <el-select
                v-model="value"
                placeholder="广告类型"
                @change="typeAdvert"
              >
                <el-option
                  v-for="item in optionsAdvertise"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>

          <el-form-item label="广告ID">
            <el-input
              class="in1"
              v-model="input"
              placeholder="请输入广告ID"
              @change="changeId"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="38px">
            <img
              src="../../assets/images/query.png"
              alt=""
              @click="searchA"
              style="width: 49px"
            />
          </el-form-item>
        </el-form>
      </div>

      <!-- <span class="span1">上架时间</span>
      <el-date-picker
        class="in1"
        v-model="value1"
        type="datetime"
        placeholder="选择日期时间"
        style="width: 200px"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        @input="$forceUpdate()"
      >
      </el-date-picker
      >-
      <el-date-picker
        class="in2"
        v-model="value2"
        type="datetime"
        placeholder="选择日期时间"
        style="width: 200px"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        @input="$forceUpdate()"
      >
      </el-date-picker>
      <span class="span1">广告类型</span>
      <el-select v-model="value" placeholder="广告类型" @change="typeAdvert">
        <el-option
          v-for="item in optionsAdvertise"
          :key="item.code"
          :label="item.name"
          :value="item.code"
        ></el-option>
      </el-select>
      <span class="span1">广告ID</span>
      <el-input
        class="in1"
        v-model="input"
        placeholder="请输入内容"
        @change="changeId"
      ></el-input>
      <img
        src="../../assets/images/query.png"
        alt=""
        @click="searchA"
        style="width: 49px"
      /> -->
    </div>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="广告ID" width="90"></el-table-column>
        <el-table-column
          prop="title"
          label="广告标题"
          width="200"
        ></el-table-column>
        <el-table-column prop="type" label="广告类型"></el-table-column>
        <el-table-column
          prop="redir"
          label="链接地址"
          width="300"
        ></el-table-column>
        <el-table-column prop="url" label="图片">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="handleSearch(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column
          prop="startDate"
          label="开始时间"
          width="170"
        ></el-table-column>
        <el-table-column
          prop="endDate"
          label="结束时间"
          width="170"
        ></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 下边 -->
    <div class="bottom">
      <div class="bottom-left">
        <el-button
          round
          class="top-btn"
          @click="addList"
          style="padding: 8px 22px; margin-top: 15px"
          >增加</el-button
        >
      </div>
      <div class="bottom-right">
        <div class="bottom-fen">
          <!-- 分页 -->
          <div class="pages">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="pageSize"
              :current-page="pageCount"
              layout="total, prev, pager, next"
              :total="totalCount"
            >
            </el-pagination>
          </div>
        </div>
        <div class="buttons">
          <el-button>数据分析</el-button>
          <el-button>数据导出</el-button>
        </div>
      </div>
    </div>
    <!-- 增加按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form :model="formInline" class="demo-form-inline" label-width="76px">
        <el-form-item label="广告类型">
          <el-select
            v-model="valueAdvert1"
            clearable
            placeholder="请选择"
            @change="typeoneAdvert1"
          >
            <el-option
              v-for="item in optionsAdvert1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="formInline.sort"
            placeholder=""
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告标题">
          <el-input
            v-model="formInline.title"
            placeholder="输入广告标题"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="上架时间">
          <el-date-picker
            class="in1"
            v-model="value3"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 200px"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            @input="$forceUpdate()"
          >
          </el-date-picker
          >-
          <el-date-picker
            class="in2"
            v-model="value4"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 200px"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            @input="$forceUpdate()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input
            v-model="formInline.redir"
            placeholder="链接"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告图片">
          <el-button
            type="primary"
            plain
            @click="pushImage"
            style="display: block; margin-bottom: 3%"
            >上传图片</el-button
          >
          <img :src="this.imageUrl" style="width: 200px" id="addAdvertImg" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="quitAddList">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改按钮的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible1"
      width="50%"
      :before-close="handleClose1"
    >
      <el-form :model="formInline" class="demo-form-inline" label-width="76px">
        <el-form-item label="广告类型">
          <el-select
            v-model="valueAdvert1"
            clearable
            placeholder="请选择"
            @change="typeoneAdvert1"
          >
            <el-option
              v-for="item in optionsAdvert1"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="formInline.sort"
            placeholder=""
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告标题">
          <el-input
            v-model="formInline.title"
            placeholder="输入广告标题"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="上架时间">
          <el-date-picker
            class="in1"
            v-model="value3"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 200px"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            @input="$forceUpdate()"
          >
          </el-date-picker
          >-
          <el-date-picker
            class="in2"
            v-model="value4"
            type="datetime"
            placeholder="选择日期时间"
            style="width: 200px"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            @input="$forceUpdate()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="链接地址">
          <el-input
            v-model="formInline.redir"
            placeholder="链接"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告图片">
          <el-button
            type="primary"
            plain
            @click="pushImage1"
            style="display: block; margin-bottom: 3%"
            >上传图片</el-button
          >
          <img :src="this.imageUrl" style="width: 200px" id="gengaiAdvertImg" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="quitAddListChange">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 增加上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible"
      width="30%"
      :before-close="handleCloseImage"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改上传图片弹框 -->
    <el-dialog
      title="上传图片"
      :visible.sync="imageVisible1"
      width="30%"
      :before-close="handleCloseImage1"
      append-to-body
    >
      <input type="file" ref="file" accept="image/*" @change="fileChange1" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="uploadImage1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 图片回显的弹框 -->
    <el-dialog
      title="产品图片"
      :visible.sync="dialogVisibleImage"
      width="30%"
      :before-close="ImagehandleClose"
    >
      <!-- <img src="http://cdn.careld.cn/datacenter/menu/product/91638329205408.png" alt=""> -->
      <el-image
        style="width: 100%; height: auto; padding-bottom: 30px"
        :src="advertImage"
      ></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      dialogVisible: false, //增加按钮的弹框
      dialogVisible1: false, //修改按钮的弹框
      imageVisible: false,
      imageVisible1: false,
      dialogVisibleImage: false,
      value1: "",
      value2: "",
      value3: "",
      value4: "",
      input: "",
      value: "",
      optionsAdvertise: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
      tableData: [],
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      advertId: "",
      typeAds: "", //广告类型
      valueAdvert1: "",
      formInline: {
        sort: "",
        title: "",
        redir: "",
      },
      optionsAdvert1: [],
      imageUrl: "",
      //七牛配置
      token: "",
      accessKey: "",
      bucket: "",
      secretKey: "",
      prefix: "",
      token1: "",
      accessKey1: "",
      bucket1: "",
      secretKey1: "",
      prefix1: "",
      advertIds: "",
      advertImage: "",
      photoUrl: "",
      flag: false,
    };
  },
  created() {
    //查询列表
    this.advertiseList();
    //广告类型列表
    this.advertiseLei();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //修改
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisible1 = true;
      this.advertIds = row.id;
      //查询产品详情
      apiShout.getAppAdvert({ id: row.id }).then((res) => {
        console.log("查询广告详情：", res.data);
        if (res.data.code == 200) {
          this.formInline = res.data.result;
          this.valueAdvert1 = row.type;
          this.value3 = res.data.result.startDate;
          this.value4 = res.data.result.endDate;
          this.imageUrl = res.data.result.url;
        }
      });
    },
    //修改的确定
    quitAddListChange() {
      this.dialogVisible1 = false;
      //获取本地
      let proPh = JSON.parse(localStorage.getItem("productPhoto"));
      if (this.flag == true) {
        this.photoUrl = "http://cdn.careld.cn/" + proPh;
      } else {
        this.photoUrl = this.imageUrl;
      }

      // this.imageUrl = proPh;
      let obj = {
        id: this.advertIds,
        type: this.typeAds,
        sort: this.formInline.sort,
        title: this.formInline.title,
        startDate: this.value3,
        endDate: this.value4,
        redir: this.formInline.redir,
        url: this.photoUrl,
      };
      apiShout.getUpAdvert(obj).then((res) => {
        console.log("修改广告：", res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "恭喜你,修改广告信息成功",
            type: "success",
          });
          //刷新页面
          this.advertiseList();
          this.flag = false;
          //清空表单
          this.advertIds = "";
          this.valueAdvert1 = "";
          this.formInline = {};
          this.value3 = "";
          this.value4 = "";
          this.imageUrl = "";
        }
      });
    },
    //增加
    typeoneAdvert1() {
      this.advertiseLei();
    },
    //增加弹框的确定
    quitAddList() {
      this.dialogVisible = false;
      //获取本地
      let proPh = JSON.parse(localStorage.getItem("productPhotoAdd"));
      this.photoUrl = proPh;
      let obj = {
        type: this.valueAdvert1,
        sort: this.formInline.sort,
        title: this.formInline.title,
        startDate: this.value3,
        endDate: this.value4,
        redir: this.formInline.redir,
        url: this.photoUrl,
      };
      // console.log('-=========:',this.imageUrl);
      apiShout.getInterAdvert(obj).then((res) => {
        console.log("增加广告：", res.data);
        if (res.data.code == 200) {
          this.$message({
            message: "恭喜你,增加广告成功",
            type: "success",
          });
          //刷新页面
          this.advertiseList();
          //清空表格
          this.photoUrl = "";
          this.valueAdvert1 = "";
          this.formInline = {};
          this.value3 = "";
          this.value4 = "";
          this.imageUrl = "";
          document.getElementById("addAdvertImg").src = "";
        }
      });
    },
    //增加点击上传图片的按钮
    pushImage() {
      this.imageVisible = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "advert" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token = res.data.result.token;
        this.accessKey = res.data.result.accessKey;
        this.bucket = res.data.result.bucket;
        this.secretKey = res.data.result.secretKey;
        this.prefix = res.data.result.prefix;
      });
    },
    //增加确定上传图片
    uploadImage() {
      this.imageVisible = false;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("advertBackImage"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token = this.token;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix + this.id + shijiancuo + ming,
        token,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("productPhotoAdd", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          document.getElementById("addAdvertImg").src = bIm;
          // document.getElementById("gengaiAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //增加上传头像格式限制
    fileChange(e) {
      // console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //保存本地
          localStorage.setItem(
            "advertBackImage",
            JSON.stringify(e.target.result)
          );
        };
      }
      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    //修改点击上传图片的按钮
    pushImage1() {
      this.imageVisible1 = true;
      //获取七牛云存储配置
      let that = this;
      that.token = JSON.parse(sessionStorage.getItem("token"));
      //console.log("token", that.token);
      api.getQiuNiu({ type: "advert" }).then((res) => {
        console.log("获取七牛云存储配置：", res.data.result);
        this.token1 = res.data.result.token;
        this.accessKey1 = res.data.result.accessKey;
        this.bucket1 = res.data.result.bucket;
        this.secretKey1 = res.data.result.secretKey;
        this.prefix1 = res.data.result.prefix;
      });
    },
    //修改确定上传图片
    uploadImage1() {
      this.imageVisible1 = false;
      this.flag = true;
      let loading = this.$loading({
        lock: true,
        text: "上传中，请稍候...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.5)",
      });
      //清空图片
      this.fileList = [];
      // console.log("===========", this.$refs.upload.uploadFiles[0]);
      this.userinfo = JSON.parse(window.localStorage.getItem("loginuser"));
      //获取本地
      let bIm = JSON.parse(localStorage.getItem("advertBackImage1"));
      this.id = this.userinfo.id;
      if (this.$refs.file.files.length === 0) {
        alert("请选择文件");
      }
      let file = this.$refs.file.files[0];
      let shijiancuo = new Date().getTime();
      let token1 = this.token1;
      //处理文件拓展名
      let indexName = file.name.lastIndexOf(".");
      let ming = file.name.slice(indexName);
      const putExtra = {};
      const config = {};
      //console.log('用户：',this.id);
      const observable = qiniu.upload(
        file,
        this.prefix1 + this.id + shijiancuo + ming,
        token1,
        putExtra,
        config
      );
      const observer = {
        complete(res) {
          console.log("hash:", res.hash, res.key);
          localStorage.setItem("productPhoto", JSON.stringify(res.key));
          // this.photoUrl = res.key;
          this.imageUrl = "http://cdn.careld.cn/" + res.key;
          loading.close();
          document.getElementById("gengaiAdvertImg").src = bIm;
          // document.getElementById("addAdvertImg").src=bIm;
          //存本地
          // alert('上传成功');
        },
      };
      observable.subscribe(observer); // 上传开始
    },
    //修改上传头像格式限制
    fileChange1(e) {
      // console.log("-----------1111:", e.target.files);
      //格式限制
      // var fileName =e.target.files.name.lastIndexOf(".") + 1;
      // if (fileName != "jpg" && fileName != "png") {
      //   alert("请选择jpg或者png格式文件上传！");
      //   return false; //阻止submit提交
      // }
      var file = e.target.files[0];
      if (window.FileReader) {
        debugger;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        //监听文件读取结束后事件
        reader.onloadend = function (e) {
          //  console.log('地址路径',e.target.result);   //e.target.result就是最后的路径地址
          //保存本地
          localStorage.setItem(
            "advertBackImage1",
            JSON.stringify(e.target.result)
          );
        };
      }
      //大小限制
      var filemaxsize = 1024 * 2; //2M
      var size = e.target.files[0].size / 1024;
      if (size > filemaxsize) {
        alert("附件大小不能大于" + filemaxsize / 1024 + "M！");
        return false;
      }
      if (size <= 0) {
        alert("附件大小不能为0M！");
        return false;
      }
      // if (e.target.files.name) {
      //   console.log('00000执行到这了吗');
      //   if (e.target.files[0].size / 1024 < 20) {
      //     this.$message.error("上传文件大小不能超过 20MB!");
      //     return false;
      //   } else if (e.target.files[0].size <= 0) {
      //     alert("文件大小不能为0M！");
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
    },
    //广告类型列表
    advertiseLei() {
      api.getSelectProduct({ dictCode: "adverttype" }).then((res) => {
        console.log("广告类型：", res.data);
        if (res.data.code == 200) {
          this.optionsAdvertise = res.data.result;
          this.optionsAdvert1 = res.data.result;
        }
      });
    },
    //广告类型
    typeAdvert(aa) {
      console.log("leixing:", aa);
      this.typeAds = aa;
    },
    //搜索
    searchA() {
      apiShout
        .getPhoneAdvert({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          id: this.advertId,
          type: this.typeAds,
          startDate: this.value1,
          endDate: this.value2,
        })
        .then((res) => {
          console.log("广告列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          }
        });
      //清空搜索内容
      // this.value = "";
      // this.input = "";
      // this.value1 = "";
      // this.value2 = "";
    },
    //图片的查看
    handleSearch(index, row) {
      console.log(index, row);
      this.dialogVisibleImage = true;
      this.advertImage = row.url;
    },
    //增加
    addList() {
      this.advertIds = "";
      this.valueAdvert1 = "";
      this.formInline = {};
      this.value3 = "";
      this.value4 = "";
      this.imageUrl = "";
      this.dialogVisible = true;
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.advertiseList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.advertiseList();
    },
    changeId(val) {
      // console.log('搜索的id：',val);
      this.advertId = val;
    },
    //增加弹框的x
    handleClose() {
      this.dialogVisible = false;
      //清空表单
      //清空表格
      this.valueAdvert1 = "";
      this.formInline = {};
      this.value3 = "";
      this.value4 = "";
      this.imageUrl = "";
      document.getElementById("addAdvertImg").src = "";
    },
    //修改弹框的x
    handleClose1() {
      this.dialogVisible1 = false;
      //清空表单
      //清空表格
      this.valueAdvert1 = "";
      this.formInline = {};
      this.value3 = "";
      this.value4 = "";
      this.imageUrl = "";
    },
    ImagehandleClose() {
      this.dialogVisibleImage = false;
    },
    handleCloseImage() {
      this.imageVisible = false;
    },
    handleCloseImage1() {
      this.imageVisible1 = false;
    },
    //查询列表
    advertiseList() {
      apiShout
        .getPhoneAdvert({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          id: this.advertId,
          type: this.typeAds,
        })
        .then((res) => {
          console.log("广告列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
.userservic {
  .search {
    height: auto;
    margin: 0 40px;
    margin-left: 25px;
    display: flex;
    align-items: center;
    .span1 {
      display: block;
      font-size: 14px;
      color: #909399;
      font-weight: bold;
      width: 80px;
      text-align: center;
    }
    .el-select {
      margin-right: 60px;
      margin-left: 10px;
    }
    .el-input {
      width: 120px;
      margin-left: 10px;
    }
    .in1 {
      margin-right: 10px;
    }
    .in2 {
      margin-right: 10px;
    }
  }
  // table
  .tables {
    margin-left: 30px;
    margin-right: 40px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      // td,
      // th.is-leaf {
      //   border: none !important;
      // }
      /deep/.el-table td.el-table__cell,
      .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      /deep/.el-table__cel {
        text-align: center;
      }
      /deep/.cell {
        text-align: center;
      }
      tr th {
        font-size: 19px;
        color: #a8a8a8;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 17px;
        color: #a8a8a8;
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
  }
  // 下边
  .bottom-right {
    float: right;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    .el-button {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #d78673;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }

  .top-btn {
    background: #d78673;
    margin-left: 30px;
    color: #ffffff;
  }
  .bottom-fen {
    margin-top: 10px;
  }
  .bottom-left {
    float: left;
  }
}
//弹框
/deep/ .el-dialog__body {
  margin: 20px;
}
/deep/.el-form-item__label {
  color: #909399;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>